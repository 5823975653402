<template>
  <div class="burger-button cursor-pointer flex items-center justify-center h-[38px] w-[38px] bg-slate-900 rounded-md hover:bg-slate-700">
    <div
      class="relative h-2.5 w-3 transition-all"
      :class="{ 'menu-burger-active rotate-180': navState === 'full' && !staticButton }"
    >
      <span class="transition-all border-b block border-slate-400 w-3 absolute top-0 right-0 menu-burger:w-4 menu-burger:-rotate-45 menu-burger:top-1/2 menu-burger:-right-0.5"/>
      <span class="transition-all border-b block border-slate-400 w-3 absolute top-1/2 right-0 menu-burger:opacity-0"/>
      <span class="transition-all border-b block border-slate-400 w-3 absolute top-full right-0 menu-burger:w-4 menu-burger:rotate-45 menu-burger:top-1/2 menu-burger:-right-0.5"/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const staticButton = ref(true); // TODO: Mariusz - Keep it static for now, I disagree with designer desision and will revisit this soon

const uiStore = useUiStore();
const {
  navState,
} = storeToRefs(uiStore);
</script>
