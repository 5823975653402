<template>
  <div v-if="visible" class="flex items-center">
    <CheckboxSwitch
      v-model="isChecked"
      label="Flags"
      :is-disabled="false"
      :small="small"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';
import { useUiStore } from '@/store/ui';

defineProps({
  small: Boolean,
});

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);
const uiStore = useUiStore();
const {
  showUserFlags,
} = storeToRefs(uiStore);

const isChecked = computed({
  get: () => showUserFlags.value?.toString() === 'true',
  set: (value) => showUserFlags.value = value.toString() === 'true',
});

const visible = computed(() => !userData.value ? false : userData.value.metadata?.showUserCountry);
</script>
